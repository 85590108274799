"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState, useMemo, useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { TimeRangeFilterOption } from "@v2/feature/attendance/attendance.interface";
import { iconSize } from "@v2/styles/menu.styles";
import dayjs from "dayjs";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ListView } from "@/v2/components/list-view/list-view.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CustomTimeRangeDrawer } from "@/v2/feature/attendance/components/custom-time-range-drawer.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ListSectionComponent = ({
  searchInput,
  setSearchInput,
  timeRange,
  setTimeRange,
  attendanceSchedules,
  scheduleFilter,
  setScheduleFilter,
  setPagination,
  listUsers,
  pagination,
  totalPages,
  totalItems,
  onPageChange,
  handleUserAttendance,
  loading,
  selectedUser
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const timeUnitOptions = useMemo(
    () => Object.values(TimeRangeFilterOption).map((timeRangeOption) => ({
      handler: () => timeRangeOption === TimeRangeFilterOption.Custom ? setIsOpen(true) : setTimeRange({ type: timeRangeOption }),
      label: polyglot.t(`TimesheetsTimeRangeFilter.${timeRangeOption}`),
      disabled: false
    })),
    [polyglot, setTimeRange]
  );
  const handleApply = (startDate, endDate) => {
    setTimeRange({ type: TimeRangeFilterOption.Custom, customStartDate: startDate, customEndDate: endDate });
    setIsOpen(false);
  };
  const handleSelectAllSchedules = useCallback(() => {
    if (!attendanceSchedules || attendanceSchedules.length === 0) return;
    const allScheduleIds = attendanceSchedules.map((s) => s.id).join(",");
    setScheduleFilter(`schedule=all,${allScheduleIds}`);
  }, [attendanceSchedules, setScheduleFilter]);
  const scheduleOptions = useMemo(() => {
    let options = [];
    if (attendanceSchedules) {
      options = attendanceSchedules.map((s) => {
        var _a;
        return {
          value: ((_a = s.id) == null ? void 0 : _a.toString()) || "",
          label: `${s == null ? void 0 : s.name}`
        };
      });
      if (options.length > 0) {
        options.unshift({
          value: "all",
          label: polyglot.t("SelectDeselectRows.selectAll")
        });
      }
    }
    return options;
  }, [attendanceSchedules, polyglot]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { pt: spacing.s2, pr: spacing.s2, gap: { xs: spacing.s1, lg: spacing.s4 } }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: { xs: spacing.s1, lg: spacing.s3 } }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceDomain.Timesheets") }),
        /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                width: "100%",
                display: "flex",
                gap: spacing.s1,
                flexDirection: { xs: "column", lg: "row" }
              },
              children: [
                /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    sx: { flex: { xs: "1", lg: "3" } },
                    options: timeUnitOptions,
                    matchButtonWidth: true,
                    disabled: loading,
                    actionButtonDetails: {
                      type: "button",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: timeRange.type === TimeRangeFilterOption.Custom ? `${dayjs(timeRange.customStartDate).format("D MMM YYYY")} - ${dayjs(
                        timeRange.customEndDate
                      ).format("D MMM YYYY")}` : polyglot.t(`TimesheetsTimeRangeFilter.${timeRange.type}`),
                      icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                      iconPosition: "end",
                      fullWidth: true,
                      style: {
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: radius.br15
                      }
                    }
                  }
                ),
                /* @__PURE__ */ jsx(Box, { sx: { flex: 1, minWidth: { xs: "auto", lg: "122px" } }, children: /* @__PURE__ */ jsx(
                  CategoryFilters,
                  {
                    filterTypes: { schedule: scheduleOptions },
                    setFilterString: (filterStr) => {
                      const wasAllSelected = scheduleFilter.includes("all");
                      const isAllSelected = filterStr.includes("all");
                      if (wasAllSelected && !isAllSelected) {
                        setScheduleFilter("");
                      } else if (isAllSelected) {
                        handleSelectAllSchedules();
                      } else {
                        setScheduleFilter(filterStr);
                      }
                    },
                    filterString: scheduleFilter,
                    buttonStyle: { width: "100%", justifyContent: "space-between" },
                    disabled: loading
                  }
                ) })
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              style: { width: "calc(100% - 28px)" },
              handleChange: (e) => {
                var _a, _b;
                setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsx(
        ListView,
        {
          list: listUsers,
          pagination: {
            pageSize: pagination.pageSize,
            pageIndex: pagination.pageIndex,
            total: totalPages,
            totalItems,
            onPageChange: (pageIndex, pageSize) => onPageChange(pageIndex, pageSize)
          },
          clickAction: (id) => handleUserAttendance(id),
          loading,
          selected: selectedUser,
          height: "calc(100vh - 171px)"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(CustomTimeRangeDrawer, { isOpen, setIsOpen, onApply: handleApply })
  ] });
};
